<template>
  <div class="w-full max-w-4xl">
    <Announcements />
    <div class="w-full max-w-4xl py-20 bg-white">
      <h2 class="mt-5 mb-20">{{ $t('common.welcome_back', [firstName]) }}</h2>
      <RewardsOverviewCard v-if="showRewards && hasBasketRewards" />
      <DirectIncentivesOverviewCard v-if="showRewards && hasDirectIncentives" :class="showRewards && hasDirectIncentives ? 'mt-16' : 'mt-0'" />
      <TrackerCarousel v-if="showTrackingCarousel" class="mt-16" />
      <ToDoToggle v-model="completed" showCounts class="mt-25 mb-30" />
      <div v-if="healthActions.length > 0">
        <ul
          v-if="!completed"
          id="incomplete-tabpanel"
          role="tabpanel"
          aria-labelledby="incomplete-tab-button"
          class="flex flex-wrap justify-center lg:justify-start"
        >
          <HealthActionCard
            v-for="healthAction in healthActions"
            :key="healthAction.id"
            :action="healthAction"
            :completed="false"
          />
        </ul>
        <ul
          v-else
          id="complete-tabpanel"
          role="tabpanel"
          aria-labelledby="complete-tab-button"
          class="flex flex-wrap justify-center lg:justify-start"
        >
          <HealthActionCard
            v-for="healthAction in healthActions"
            :key="healthAction.id"
            :action="healthAction"
            :completed="true"
          />
        </ul>
      </div>
      <div v-else class="px-20 text-center">
        <Icon
          :iconClass="
            completed ? 'fas fa-clipboard-list' : 'fas fa-clipboard-check'
          "
          class="text-6xl rounded-full w-100 h-100 bg-grey-200"
        />
        <p class="mt-25">
          {{
            $t(
              completed
                ? 'health_actions.home.no_completed_desc'
                : 'health_actions.home.all_completed_desc'
            )
          }}
        </p>
      </div>
      <Modal v-if="showPhaWelcome" :active="showPhaWelcome">
        <WelcomeModal />
      </Modal>
      <Modal v-else-if="hraModal" :active="hraModal">
        <HRAModal @close="hraModal = false" />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HealthActionCard from './HealthActionCard'
import HRAModal from './StartResumeHra'
import WelcomeModal from './pha-welcome/WelcomeModal'
import TrackerCarousel from './TrackerCarousel'
import { HEALTH_ACTION_CODES as healthActionCodes } from '@platform-shared/constants'
import Modal from '@components/common/Modal'
import getTime from 'date-fns/getTime'
import subWeeks from 'date-fns/subWeeks'
import startOfDay from 'date-fns/startOfDay'
import _find from 'lodash/find'
import Announcements from './Announcements'
import { FEATURES } from '@platform-shared/constants'
import ToDoToggle from './ToDoToggle'
import RewardsOverviewCard from '../rewards/RewardsOverviewCard'
import DirectIncentivesOverviewCard from '../rewards/DirectIncentivesOverviewCard'

export default {
  components: {
    Announcements,
    TrackerCarousel,
    HealthActionCard,
    Modal,
    HRAModal,
    WelcomeModal,
    ToDoToggle,
    RewardsOverviewCard,
    DirectIncentivesOverviewCard,
  },
  data() {
    return {
      completed: false,
      hraModal: false,
      hraModalViewTime: parseInt(localStorage.getItem('hraModalViewTime')) || 0,
      fteViewTime: parseInt(localStorage.getItem('fteViewTime')) || 0,
    }
  },
  computed: {
    ...mapGetters('member', ['firstName', 'showPhaWelcome']),
    ...mapGetters('healthActions', { healthActionsHasLoaded: 'hasLoaded' }),
    ...mapGetters('healthActions', [
      'completedHealthActions',
      'incompleteHealthActions',
    ]),
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('tracking', ['trackers']),
    ...mapGetters('rewards', ['hasBasketRewards', 'hasDirectIncentives']),
    shouldShowHRA() {
      if (this.hraModalViewTime === 0) {
        localStorage.setItem('hraModalViewTime', getTime(new Date()))
        return false
      }

      return (
        !!_find(this.incompleteHealthActions, {
          code: healthActionCodes.COMPLETE_HEALTH_QUESTIONNAIRE,
        }) &&
        this.hraModalViewTime < getTime(subWeeks(new Date(), 1)) &&
        this.fteViewTime < getTime(startOfDay(new Date()))
      )
    },
    healthActions() {
      return this.completed
        ? this.completedHealthActions
        : this.incompleteHealthActions
    },
    showRewards() {
      return this.hasFeature(FEATURES.REWARDS)
    },
    showTrackingCarousel() {
      return (
        this.hasFeature(FEATURES.TRACKING) &&
        this.trackers.length > 0 &&
        this.hasFeature(FEATURES.TRACKING_ON_HOME_SCREEN)
      )
    },
  },
  watch: {
    healthActionsHasLoaded(newValue, oldValue) {
      if (newValue && !oldValue && this.shouldShowHRA) {
        this.hraModal = true
      }
    },
  },
  created() {
    if (this.showRewards) {
      this.initRewards()
    }

    this.initQuestionnaires()
    this.initHealthActions()

    if (this.hasFeature(FEATURES.TRACKING)) {
      this.initTracking()
    }
  },
  methods: {
    ...mapActions('rewards', ['initRewards']),
    ...mapActions('healthActions', ['initHealthActions']),
    ...mapActions('questionnaire', ['initQuestionnaires']),
    ...mapActions('tracking', ['initTracking']),
  },
}
</script>
