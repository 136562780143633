<template>
  <div class="flex flex-col items-center">
    <form class="flex">
      <input
        v-for="(field, i) in fields"
        :id="i"
        :key="i"
        ref="codeField"
        :value="fields[i]"
        type="text"
        maxlength="1"
        autocomplete="off"
        :disabled="disabled"
        class="bg-grey-100 mx-5 rounded border border-grey-300 outline-none text-xl pl-10 focus:border-grey-500"
        :style="{ width: '32px', height: '50px' }"
        @input="setField"
        @keydown="handleNonAlpha"
        @paste="paste"
        @click="() => fieldClick(i)"
      />
      <input ref="reset" type="reset" class="hidden" />
    </form>
    <p v-if="codeError" class="text-red-500 mt-10 text-center">
      {{ $t('mfa.incorrect_code') }}
      <br />
      {{ $t('mfa.try_again') }}
    </p>
    <HMButton
      ref="mfaConfirm"
      rounded="full"
      class="mt-20"
      :disabled="!canVerify || isSubmittingTotp"
      @click="submitCode"
    >
      {{ $t('mfa.confirm') }}
    </HMButton>
  </div>
</template>
<script>
import _slice from 'lodash/slice'
import { mapGetters } from 'vuex'

export default {
  props: {
    codeError: {
      type: Boolean,
      default: false,
    },
    fieldCount: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: Array(this.fieldCount).fill(''),
      focusIdx: 0,
    }
  },
  computed: {
    ...mapGetters('member', ['isSubmittingTotp']),
    observableCode() {
      return this.fields.filter((field) => field !== '').join('')
    },
    canVerify() {
      return this.observableCode.length === this.fieldCount
    },
  },
  watch: {
    canVerify(newValue, oldValue) {
      if (newValue && !oldValue) {
        // nextTick is needed beacuse the DOM won't update the button's disabled attribute and focus at the same time
        this.$nextTick(this.$refs.mfaConfirm.$refs.button.focus)
      }
    },
    codeError(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.fields = Array(this.fieldCount).fill('')
        this.focusIdx = 0
        this.$refs.reset.click()
        this.$refs.codeField[0].focus()
      }
    },
  },
  mounted() {
    this.$refs.codeField[0].focus()
  },
  methods: {
    resetComponent() {
      this.fields = Array(this.fieldCount).fill('')
      this.focusIdx = 0
      this.$nextTick(() => {
        this.$refs.reset.click()
        this.$refs.codeField[0].focus()
      })
    },
    submitCode() {
      this.$emit('confirm', this.observableCode)
      this.resetComponent()
    },
    moveRight() {
      const nextIdx = this.focusIdx + 1
      if (nextIdx < this.fieldCount) {
        this.focusNext(nextIdx)
      }
    },
    moveLeft() {
      const prevIdx = this.focusIdx - 1
      if (prevIdx >= 0) {
        this.focusNext(prevIdx)
      }
    },
    paste(e) {
      e.preventDefault()
      if (this.disabled) {
        return
      }
      const code = _slice(e.clipboardData.getData('text'), 0, this.fieldCount)
      if (!isNaN(parseInt(code))) {
        this.fields = this.fields.map((_, idx) => {
          const field = code[idx] || ''
          this.$refs.codeField[idx].value = field
          return field
        })
      }
    },
    deleteField() {
      const emptyField = this.$refs.codeField[this.focusIdx].value === ''
      if (!emptyField) {
        const tempFields = [...this.fields]
        tempFields[this.focusIdx] = ''
        this.fields = tempFields
        this.$refs.codeField[this.focusIdx].value = ''
      }
      const prevIdx = this.focusIdx - 1
      if (prevIdx >= 0) {
        this.focusNext(prevIdx)
      }
    },
    setField(e) {
      const digit = e.target.value
      if (!isNaN(digit) && this.focusIdx < this.fieldCount) {
        e.preventDefault()
        const tempFields = [...this.fields]
        tempFields[this.focusIdx] = digit
        this.fields = tempFields
        this.$refs.codeField[this.focusIdx].value = digit
        const nextIdx = this.focusIdx + 1
        if (nextIdx < this.fieldCount) {
          this.focusNext(nextIdx)
        }
      }
    },
    handleNonAlpha(e) {
      switch (e.key) {
        case 'Backspace':
          e.preventDefault()
          this.deleteField()
          return
        case 'ArrowRight':
          e.preventDefault()
          this.moveRight()
          return
        case 'ArrowLeft':
          e.preventDefault()
          this.moveLeft()
          return
      }
    },
    focusNext(nextIdx) {
      this.$refs.codeField[nextIdx].select()

      if (nextIdx > this.focusIdx) {
        this.focusIdx += 1
      } else {
        this.focusIdx -= 1
      }
    },
    fieldClick(i) {
      this.$refs.codeField[i].select()
      this.focusIdx = i
    },
  },
}
</script>
