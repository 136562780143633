<template>
  <main>
    <div class="flex pt-150">
      <div class="w-full flex flex-col">
        <div
          v-if="hasHeaderSlot"
          class="w-full pt-25 flex items-center justify-between mb-20"
        >
          <slot name="header" />
        </div>
        <div
          class="flex-grow items-center justify-center"
          :class="{ 'flex flex-col': !topAlign }"
        >
          <div class="w-full mx-auto">
            <slot />
            <div v-if="showContact" class="mt-35 text-center">
              <button
                class="inline-block text-gray-400 font-semibold"
                @click="supportModalOpen = true"
              >
                {{ $t('common.contact_support') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal :active="supportModalOpen" closeOnBackDrop @close="closeModal">
        <ContactSupportModal @close="closeModal" />
      </Modal>
    </div>
  </main>
</template>

<script>
import ContactSupportModal from '@components/common/ContactSupportModal'
import Modal from '@components/common/Modal'

export default {
  components: { ContactSupportModal, Modal },
  props: {
    showContact: {
      type: Boolean,
      default: true,
    },
    topAlign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportModalOpen: false,
    }
  },
  computed: {
    hasHeaderSlot() {
      return this.$slots.header
    },
  },
  methods: {
    closeModal() {
      this.supportModalOpen = false
    },
  },
}
</script>
