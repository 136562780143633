<template>
  <h2 class="h5">
    {{ $t(`rewards.landing.available_rewards.dollars`) }}
    <span class="text-green-500">
      {{ availableRewardsCount }}
    </span>
  </h2>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('rewards', ['validDirectIncentives']),
    availableRewardsCount() {
      return this.validDirectIncentives.length
    } 
  },
}
</script>
