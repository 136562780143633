import { mapGetters, mapActions } from 'vuex'
import { EMAIL_VERIFICATION_TYPES } from '../constants'

export default {
  data() {
    return {
      showCodeSent: false,
      verifyCodeError: false,
    }
  },
  computed: {
    ...mapGetters('member', [
      'email',
      'emailAddressVerifiedTsTz',
      'enrollmentEmail',
      'enrollmentEmailAddressVerifiedTsTz',
    ]),

    primaryType() {
      return EMAIL_VERIFICATION_TYPES.VERIFY_EMAIL_ADDRESS
    },
    enrollmentType() {
      return EMAIL_VERIFICATION_TYPES.VERIFY_ENROLLMENT_EMAIL_ADDRESS
    },
    emailsToVerify() {
      const needsPrimaryVerification =
        !this.emailAddressVerifiedTsTz ||
        this.emailAddressVerifiedTsTz.trim() === ''
      const needsEnrollmentVerification =
        (!this.enrollmentEmailAddressVerifiedTsTz ||
          this.enrollmentEmailAddressVerifiedTsTz.trim() === '') &&
        this.enrollmentEmail

      if (needsPrimaryVerification && needsEnrollmentVerification) {
        return 'enrollment'
      }

      if (needsPrimaryVerification) return 'primary'

      return 'confirmation'
    },
  },
  methods: {
    ...mapActions('member', [
      'requestVerificationTotp',
      'submitVerificationTotp',
    ]),
    ...mapActions('rewards', ['initRewards']),
    requestVerification(type) {
      this.requestVerificationTotp({ type })
        .then(() => {
          this.showCodeSent = true
          setTimeout(() => {
            this.showCodeSent = false
          }, 5000)
        })
        .catch(console.error)
    },
    submitVerification(totp, type) {
      this.verifyCodeError = false
      return this.submitVerificationTotp({ totp, type })
        .then(() => {
          this.initRewards(true)
          return true
        })
        .catch(() => {
          this.verifyCodeError = true
          return false
        })
    },
  },
}
