<template>
  <div
    class="flex flex-col items-center p-40 my-40 border rounded border-grey-300"
  >
    <h6 class="text-center">{{ displayTitle }}</h6>
    <p class="mt-20 text-center">
      {{ $t('rewards.verification.sent_code_standalone') }}
    </p>
    <p class="mt-20 text-center font-semibold">
      {{ displaySentEmail }}
    </p>
    <p class="mt-20 text-center">
      {{ $t('rewards.verification.spam_notice') }}
    </p>
    <p class="text-center">{{ $t('rewards.verification.code_expiration') }}</p>
    <CodeInput class="mt-20" :codeError="codeError" @confirm="submitCode" />
    <div v-if="showCodeSent" class="flex items-center mt-20">
      <Icon iconClass="far fa-check-circle text-green-500" />
      <span class="ml-10">{{ $t('mfa.resend_code_sent') }}</span>
    </div>
    <div v-else class="mt-20">
      {{ $t('mfa.resend_prompt') }}
      <a class="text-blue-500 underline" @click="resend">
        {{ $t('mfa.resend_code') }}
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CodeInput from '@components/common/forms/CodeInput'
import emailVerificationMixin from '@platform-shared/mixins/emailVerificationMixin'

export default {
  components: { CodeInput },
  mixins: [emailVerificationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    codeError: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('member', ['email', 'enrollmentEmail']),
    displayTitle() {
      return this.type === this.primaryType
        ? this.$t('rewards.verification.step_two_title')
        : this.$t('rewards.verification.step_one_title')
    },
    displaySentEmail() {
      return this.type === this.primaryType ? this.email : this.enrollmentEmail
    },
  },
  created() {
    this.resend()
  },
  methods: {
    submitCode(totp) {
      this.$emit('next', totp, this.type)
    },
    resend() {
      this.requestVerification(this.type)
    },
  },
}
</script>
