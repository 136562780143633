import _find from 'lodash/find'
import _get from 'lodash/get'
import _head from 'lodash/head'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _capitalize from 'lodash/capitalize'
import _max from 'lodash/max'
import _min from 'lodash/min'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import mapValues from 'lodash/fp/mapValues'
import reduce from 'lodash/fp/reduce'
import parseISO from 'date-fns/parseISO'
import getYear from 'date-fns/getYear'
import startOfDay from 'date-fns/startOfDay'
import isWithinInterval from 'date-fns/isWithinInterval'
import isBefore from 'date-fns/isBefore'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'

import {
  NULL_COVERAGE_END_DATE,
  GENDER,
  ACTIVITY_TYPES,
  AGREEMENT_TYPES,
  AGREEMENT_RECURRENCE_TYPES,
  TOKEN_LOGIN_TYPES,
} from '@platform-shared/constants'
import { addDays } from 'date-fns'

const isWithinIntervalErrorHandled = (date, startDate, endDate) => {
  try {
    return isWithinInterval(date, { start: startDate, end: endDate })
  } catch (error) {
    return false
  }
}

// for effective coverage first try getting the member coverage that is active
// otherwise try getting an ended member coverage with the closest end date (for the case of a termed member)
const getEffectiveMemberCoverage = (memberCoverages) =>
  _find(memberCoverages, (memberCoverage) =>
    isWithinIntervalErrorHandled(
      startOfDay(new Date()),
      parseISO(memberCoverage.startDate),
      parseISO(_get(memberCoverage, 'endDate', NULL_COVERAGE_END_DATE))
    )
  ) ||
  flow(
    filter(
      ({ endDate }) =>
        !!endDate && isBefore(parseISO(endDate), startOfDay(new Date()))
    ),
    orderBy('endDate', 'desc'),
    _head
  )(memberCoverages)

const getNonTermedMemberCoverages = (memberCoverages) =>
  filter(
    (memberCoverage) =>
      isWithinIntervalErrorHandled(
        startOfDay(new Date()),
        parseISO(memberCoverage.startDate),
        parseISO(_get(memberCoverage, 'endDate', NULL_COVERAGE_END_DATE))
      ) ||
      (!!memberCoverage.endDate &&
        isBefore(parseISO(memberCoverage.endDate), startOfDay(new Date())))
  )(memberCoverages)

export const getCurrentCompliancePeriod = (
  compliancePeriods,
  cohortIncentiveStrategyType
) =>
  flow(
    filter(
      (compliancePeriod) =>
        compliancePeriod.cohortIncentiveStrategyType ===
          cohortIncentiveStrategyType &&
        isWithinInterval(startOfDay(new Date()), {
          start: parseISO(compliancePeriod.startDate),
          end: parseISO(compliancePeriod.endDate),
        })
    ),
    reduce(
      (acc, cp) => {
        const cis = {
          compliancePeriodId: cp.id,
          cohortIncentiveStrategyId: cp.cohortIncentiveStrategyId,
          cohortIncentiveStrategyType: cp.cohortIncentiveStrategyType,
        }

        acc.cohortIncentiveStrategies.push(cis)

        /* NOTE:
         * The use of acc.startDate, acc.endDate, & acc.maxRewardAmount should be treated
         * as deprecated fields that'll be replaced by acc.cohortIncentiveStrategies.
         * */
        acc.startDate = _max([acc.startDate, cp.startDate])
        acc.endDate = _min([acc.endDate, cp.endDate])
        acc.maxRewardAmount = _min([acc.maxRewardAmount, cp.maxRewardAmount])

        return acc
      },
      {
        cohortIncentiveStrategies: [],
      }
    )
  )(compliancePeriods)

const formatDate = (date) => {
  if (!date) return ''

  const parsedDate = parseISO(date)
  const zone = parsedDate.toString().match(/\((.*?)\)/)
  return format(parsedDate, 'MM/dd/yy h:mm a') + ` ${zone ? zone[1] : ''}`
}

export default {
  isLoggingIn: (state) => state.isFetching.login,
  isUnregistering: (state) => state.isFetching.unregister,
  isLoadingAgreements: (state) => state.isFetching.agreements,
  isGettingMemberInfo: (state) => state.isFetching.memberInfo,
  isUpdatingLanguagePref: (state) => state.isFetching.languagePref,
  loadingFromLoginScreen: (state) => state.isFetching.memberInfoFromLoginScreen,
  isSubmittingTotp: (state) => state.isFetching.verificationTotp,
  accessToken: (state) => state.accessToken,
  tokenExpiration: (state) => state.tokenExpiration,
  tokenLoginType: (state) => state.tokenLoginType,
  firstName: (state) => _capitalize(state.firstName),
  lastName: (state) => _capitalize(state.lastName),
  impersonatedMemberId: (state) => state.impersonatedMemberId,
  impersonationEmail: (state) => state.impersonationEmail,
  fullName: (state, getters) => `${getters.firstName} ${getters.lastName}`,
  isFetching: (state) => state.isFetching,
  preferredLanguageCd: (state) => state.preferredLanguageCd,
  preAuthPreferredLanguageCd: (state) => state.preAuthPreferredLanguageCd,
  isAuthenticated: (state, getters) => {
    const accessToken = getters.accessToken

    if (!accessToken) return false

    const isExpired = getters.tokenExpiration < new Date() / 1000

    return !isExpired
  },

  memberCoverageInfo: (state, getters, rootState, rootGetters) => {
    const memberCoverages = _get(state, 'memberCoverages', [])
    const cohortIncentiveStrategyType =
      rootGetters['client/cohortIncentiveStrategyType']
    const effectiveMemberCoverage = getEffectiveMemberCoverage(memberCoverages)

    const allNonTermedMemberCoverages =
      getNonTermedMemberCoverages(memberCoverages) || []

    const compliancePeriods = allNonTermedMemberCoverages.flatMap((coverages) =>
      _get(coverages, 'coverage.compliancePeriods', [])
    )

    const currentCompliancePeriod = getCurrentCompliancePeriod(
      compliancePeriods,
      cohortIncentiveStrategyType
    )

    return {
      memberId: _get(state, 'id'),
      memberCoverageId: _get(effectiveMemberCoverage, 'id'),
      cohortId: _get(effectiveMemberCoverage, 'coverage.cohortId'),
      currentCompliancePeriod,
      effectiveMemberCoverage,
    }
  },
  contract: (state, getters) =>
    _get(getters, [
      'memberCoverageInfo',
      'effectiveMemberCoverage',
      'coverage',
      'medicalPlan',
      'medicalContract',
    ]),
  cohortId: (_, getters) => {
    return getters.memberCoverageInfo.cohortId
  },
  contractLogo: (state, getters, rootState, rootGetters) => {
    const memberContractCode = _get(getters, 'contract.code')

    return rootGetters['client/contractLogo'](memberContractCode)
  },
  memberInfo: (state) => {
    const address = _find(state.addresses, 'primary')
    return {
      id: state.id,
      username: state.username,
      email: state.email,
      address: address,
      phoneNumbers: state.phoneNumbers,
      addressText: address
        ? `${address.streetAddr1} ${
            address.streetAddr2 ? address.streetAddr2 + ',' : ''
          } ${address.city} ${address.state} ${address.zip}`
        : '',
      client: state.client,
      ethnography: state.ethnography,
      lastLogin: formatDate(_get(state.lastLogin, 'activityTsTz')),
    }
  },

  dependents: (state) =>
    _map(state.dependents, ({ id, firstName, lastName, memberCoverages }) => {
      const effectiveMemberCoverage = getEffectiveMemberCoverage(
        memberCoverages
      )
      return {
        id,
        memberCoverageId: _get(effectiveMemberCoverage, 'id'),
        firstName: _capitalize(firstName),
        lastName: _capitalize(lastName),
      }
    }),

  dependentsHeader: (state, getters) =>
    _size(getters.dependents) > 0
      ? JSON.stringify(
          _map(getters.dependents, ({ id, memberCoverageId }) => ({
            memberId: id,
            memberCoverageId,
          }))
        )
      : '',
  getMemberNameById: (state, getters) => (id, returnFullName = false) => {
    const memberIsSubscriber = id === state.id
    const dependent =
      !memberIsSubscriber &&
      _find(getters.dependents, ({ id: memberId }) => memberId === id)

    return memberIsSubscriber
      ? 'common.you'
      : returnFullName
      ? `${dependent.firstName} ${dependent.lastName}`
      : dependent.firstName
  },

  address: (state) => {
    const address = _find(state.addresses, 'primary')

    return address
      ? `${address.streetAddr1} ${
          address.streetAddr2 ? address.streetAddr2 + ',' : ''
        } ${address.city} ${address.state} ${address.zip}`
      : ''
  },
  activeMemberCoverages: (state, getters, rootState, rootGetters) => {
    const memberCoverages = _get(state, 'memberCoverages')
    const today = startOfDay(new Date())

    return memberCoverages.filter((coverage) => {
      const startDate = parseISO(coverage.startDate)
      const endDate = parseISO(coverage.endDate)

      const startOfStartDate = startOfDay(startDate)

      if (!coverage.endDate && !isBefore(today, startOfStartDate)) {
        return true
      } else if (!coverage.endDate) {
        return false
      }

      const termPeriodDayCount = rootGetters['client/termPeriodDayCount']

      const end = addDays(endOfDay(endDate), termPeriodDayCount)
      return isWithinIntervalErrorHandled(today, startOfStartDate, end)
    })
  },
  activeCohortIncentiveStrategies: (state, getters) => {
    const memberCoverages = getters.activeMemberCoverages

    return memberCoverages.flatMap((memberCoverage) => {
      const { coverage } = memberCoverage
      const currentCompliancePeriod = getCurrentCompliancePeriod(
        coverage.compliancePeriods
      )
      return currentCompliancePeriod.cohortIncentiveStrategies.map(
        (x) => x.cohortIncentiveStrategyId
      )
    })
  },
  isLoadingNotificationSettings: (state) =>
    _get(state, 'isFetching.notificationSettings', false),
  communicationPreferences: (state) =>
    _get(state, 'communicationPreferences', []),

  email: (state) => state.email,
  emailAddressVerifiedTsTz: (state) => state.emailAddressVerifiedTsTz,
  enrollmentEmail: (state) => state.enrollmentEmail,
  enrollmentEmailAddressVerifiedTsTz: (state) =>
    state.enrollmentEmailAddressVerifiedTsTz,
  requiresEmailVerification(state) {
    const needsPrimaryVerification =
      !state.emailAddressVerifiedTsTz ||
      state.emailAddressVerifiedTsTz.trim() === ''
    return needsPrimaryVerification
  },

  username: (state) => state.username,

  primaryPhoneNumber: (state) => {
    const primary = _get(state.phoneNumbers, 'primary', '')
    return _get(state.phoneNumbers, `${primary.toLowerCase()}`, null)
  },

  genderName: (state) => _get(GENDER, state.gender, 'N/A'),
  gender: (state) => _get(state, 'gender', null),
  age: (state) => _get(state, 'age', null),

  disclaimers: (state) => orderBy('displayOrder', 'asc', state.disclaimers),
  disclaimerById: (state) => (id) =>
    _find(state.disclaimers, (disclaimer) => disclaimer.id == id),

  agreements: (state) => {
    const today = startOfDay(new Date())
    const currentYear = getYear(today)

    return flow(
      getOr({}, 'agreementCatalog'),
      mapValues((agreement) => {
        const {
          id,
          recurrenceType,
          recurrenceWindowStart,
          recurrenceWindowEnd,
        } = agreement
        const windowStart = recurrenceWindowStart
          ? new Date(`${recurrenceWindowStart}/${currentYear}`)
          : null
        const windowEnd = recurrenceWindowEnd
          ? new Date(`${recurrenceWindowEnd}/${currentYear}`)
          : null

        const memberAgreements = _get(
          state.agreements,
          ['myAgreements', id],
          []
        )
        const mostRecentTimeStamp = startOfDay(
          parseISO(_get(_head(memberAgreements), 'agreementTimestamp'))
        )
        const requiresRenewal = () => {
          if (recurrenceType === AGREEMENT_RECURRENCE_TYPES.ONCE) {
            return _isEmpty(memberAgreements)
          }

          if (recurrenceType === AGREEMENT_RECURRENCE_TYPES.ANNUAL_CALENDAR) {
            return (
              _isEmpty(memberAgreements) ||
              getYear(parseISO(mostRecentTimeStamp)) < currentYear
            )
          }

          if (recurrenceType === AGREEMENT_RECURRENCE_TYPES.ANNUAL_WINDOW) {
            return (
              isWithinInterval(today, { start: windowStart, end: windowEnd }) &&
              (_isEmpty(memberAgreements) ||
                !isWithinInterval(new Date(mostRecentTimeStamp), {
                  start: windowStart,
                  end: windowEnd,
                }))
            )
          }

          return false
        }
        return {
          ...agreement,
          memberAgreements,
          requiresRenewal: requiresRenewal(),
        }
      })
    )(state.agreements)
  },
  pendingAgreements: (state, getters) => (type) =>
    filter(
      (agreement) => !!agreement.requiresRenewal && type === agreement.type
    )(getters.agreements),
  pendingPrivacyAgreements: (state, getters) =>
    getters.pendingAgreements(AGREEMENT_TYPES.PRIVACY_AGREEMENT),
  unseenAnnouncements: (state, getters) =>
    filter(
      (agreement) =>
        !!agreement.requiresRenewal &&
        agreement.type === AGREEMENT_TYPES.ANNOUNCEMENT
    )(getters.agreements),
  summaryOfBenefits: (state) => _get(state, 'summaryOfBenefits', []),
  showPhaWelcome: (state, getters) => {
    const agreement = _find(getters.agreements, {
      type: AGREEMENT_TYPES.PHA_WELCOME,
    })
    return _get(agreement, 'requiresRenewal')
  },
  memberLoginUrl: (state) => state.memberLoginUrl,
  memberPortalUrl: (state) => state.memberPortalUrl,
  isSsoSamlIdpInit: (state, getters) =>
    getters.tokenLoginType === TOKEN_LOGIN_TYPES.SSO_SAML_IDP_INIT,
  isSsoSamlSpInit: (state, getters) =>
    getters.tokenLoginType === TOKEN_LOGIN_TYPES.SSO_SAML_SP_INIT,
  isSsoOidc: (state, getters) =>
    getters.tokenLoginType === TOKEN_LOGIN_TYPES.SSO_OIDC,
  isSSOLogin: (state, getters) =>
    getters.isSsoSamlIdpInit || getters.isSsoSamlSpInit || getters.isSsoOidc,
  showSSORegistration: (state, getters) =>
    !getters.impersonatedMemberId &&
    getters.isSSOLogin &&
    _isEmpty(
      getters.getActivityByType(ACTIVITY_TYPES.SSO_MEMBER_REGISTRATION_INFO)
    ),
  showDependents: (state, getters) => getters.dependents.length > 0,
  getActivityByType: (state) => (type) => getOr([], type)(state.activity),

  /* MFA Getters */
  mfaEnrolled: (state) => state.mfaEnrolled,
  mfaType: (state) => state.mfaType,
  qrImage: (state) => state.mfaQrImage,
  qrLoading: (state) => state.isFetching.mfaQrImage,
  utmParams: (state) => state.utmParams,
}
