<template>
  <div>
    <ToDoNotesIconSection />
    <Body class="text-center">
      {{ basicDescription }}
    </Body>
  </div>
</template>

<script>
import ToDoNotesIconSection from './ToDoNotesIconSection.vue'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: {
    ToDoNotesIconSection,
  },
  mixins: [healthActionMixin],
}
</script>
