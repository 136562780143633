<template>
  <div
    class="flex flex-col items-center p-40 my-40 border rounded border-grey-300"
  >
    <div
      class="flex items-center justify-center rounded-full h-80 w-80 bg-sky-100"
    >
      <Icon
        iconClass="fas fa-check-circle text-sky-500"
        :style="{ fontSize: '48px' }"
      />
    </div>
    <h6 class="text-center pt-20">
      {{ $t('rewards.verification.email_verification_success') }}
    </h6>
    <p class="mt-20 text-center">
      {{ $t('rewards.verification.redemption_access') }}
    </p>
    <p class="mt-20 text-center">
      {{ $t('rewards.verification.digital_send_to') }}
    </p>
    <p class="text-center font-semibold">
      {{ displaySentEmail }}
    </p>
    <HMButton rounded="full" class="mt-20" @click="$router.push('/rewards')">
      {{ $t('rewards.verification.return') }}
    </HMButton>
  </div>
</template>
<script>
import Icon from '~/components/common/Icon.vue'
import emailVerificationMixin from '@platform-shared/mixins/emailVerificationMixin'

export default {
  components: { Icon },
  mixins: [emailVerificationMixin],
  computed: {
    displaySentEmail() {
      return this.email
    },
  },
}
</script>
