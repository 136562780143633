export default {
  RESET_STATE: 'member/reset_state',
  LOGIN_FAILURE: 'member/login/failure',
  LOGIN_REQUEST: 'member/login/request',
  LOGIN_SUCCESS: 'member/login/success',
  STORE_TOKEN: 'member/store_token',
  SAVE_MEMBER_INFO: 'member/save_member_info',
  MEMBER_INFO_REQUEST: 'member/member_info/request',
  MEMBER_INFO_SUCCESS: 'member/member_info/success',
  MEMBER_INFO_FAILURE: 'member/member_info/failure',
  CONTACT_INFO_UPDATE_FAILURE: 'contact_info/update/failure',
  CONTACT_INFO_UPDATE_REQUEST: 'contact_info/update/request',
  CONTACT_INFO_UPDATE_SUCCESS: 'contact_info/update/success',
  ADDRESS_UPDATE_FAILURE: 'address/update/failure',
  ADDRESS_UPDATE_REQUEST: 'address/update/request',
  ADDRESS_UPDATE_SUCCESS: 'address/update/success',
  PASSWORD_UPDATE_FAILURE: 'password/update/failure',
  PASSWORD_UPDATE_REQUEST: 'password/update/request',
  PASSWORD_UPDATE_SUCCESS: 'password/update/success',
  ETHNOGRAPHY_UPDATE_FAILURE: 'ethnography/update/failure',
  ETHNOGRAPHY_UPDATE_REQUEST: 'ethnography/update/request',
  ETHNOGRAPHY_UPDATE_SUCCESS: 'ethnography/update/success',
  INIT_AGREEMENTS_SUCCESS: 'member/init_agreements/success',
  CREATE_AGREEMENT_REQUEST: 'member/create_agreement/request',
  CREATE_AGREEMENT_SUCCESS: 'member/create_agreement/success',
  CREATE_AGREEMENT_FAILURE: 'member/create_agreement/failure',
  INIT_ACTIVITY_SUCCESS: 'init_activity/success',

  PREFERRED_LANGUAGE_REQUEST: 'prefered_language/request',
  PREFERRED_LANGUAGE_SUCCESS: 'prefered_language/success',
  PREFERRED_LANGUAGE_FAILURE: 'prefered_language/failure',
  SET_PREFERRED_LANGUAGE: 'set_preferred_language',
  SET_PREFERRED_LANGUAGE_PRE_AUTH: 'set_preferred_language_pre_auth',

  /* MFA Types */
  BEGIN_MFA_ENROLLMENT_REQUEST: 'begin_mfa_enrollment/request',
  BEGIN_MFA_ENROLLMENT_SUCCESS: 'begin_mfa_enrollment/success',
  BEGIN_MFA_ENROLLMENT_FAILURE: 'begin_mfa_enrollment/failure',

  COMPLETE_MFA_ENROLLMENT_REQUEST: 'complete_mfa_enrollment/request',
  COMPLETE_MFA_ENROLLMENT_SUCCESS: 'complete_mfa_enrollment/success',
  COMPLETE_MFA_ENROLLMENT_FAILURE: 'complete_mfa_enrollment/failure',

  BEGIN_MFA_UNENROLLMENT_REQUEST: 'begin_mfa_unenrollment/request',
  BEGIN_MFA_UNENROLLMENT_SUCCESS: 'begin_mfa_unenrollment/success',
  BEGIN_MFA_UNENROLLMENT_FAILURE: 'begin_mfa_unenrollment/failure',

  COMPLETE_MFA_UNENROLLMENT_REQUEST: 'complete_mfa_unenrollment/request',
  COMPLETE_MFA_UNENROLLMENT_SUCCESS: 'complete_mfa_unenrollment/success',
  COMPLETE_MFA_UNENROLLMENT_FAILURE: 'complete_mfa_unenrollment/failure',

  REQUEST_MFA_REQUEST: 'mfa_request_code/request',
  REQUEST_MFA_SUCCESS: 'mfa_request_code/success',
  REQUEST_MFA_FAILURE: 'mfa_request_code/failure',

  VERIFY_MFA_CODE_REQUEST: 'verify_mfa_code/request',
  VERIFY_MFA_CODE_SUCCESS: 'verify_mfa_code/success',
  VERIFY_MFA_CODE_FAILURE: 'verify_mfa_code/failure',

  EMAIL_VERIFICATION_TOTP_REQUEST: 'email_verification_totp/request',
  EMAIL_VERIFICATION_TOTP_SUCCESS: 'email_verification_totp/success',
  EMAIL_VERIFICATION_TOTP_FAILURE: 'email_verification_totp/failure',

  SUBMIT_EMAIL_VERIFICATION_TOTP_REQUEST:
    'submit_email_verification_totp/request',
  SUBMIT_EMAIL_VERIFICATION_TOTP_SUCCESS:
    'submit_email_verification_totp/success',
  SUBMIT_EMAIL_VERIFICATION_TOTP_FAILURE:
    'submit_email_verification_totp/failure',

  NOTIFICATION_SETTINGS_REQUEST: 'notification_settings/request',
  NOTIFICATION_SETTINGS_SUCCESS: 'notification_settings/success',
  NOTIFICATION_SETTINGS_FAILURE: 'notification_settings/failure',

  UNSUBSCRIBE_REQUEST: 'unsubscribe/request',
  UNSUBSCRIBE_SUCCESS: 'unsubscribe/success',
  UNSUBSCRIBE_FAILURE: 'unsubscribe/failure',

  UNREGISTER_MEMBER_REQUEST: 'unregister_member/request',
  UNREGISTER_MEMBER_SUCCESS: 'unregister_member/success',
  UNREGISTER_MEMBER_FAILURE: 'unregister_member/failure',

  GET_DISCLAIMERS_SUCCESS: 'get_disclaimers/success',
  GET_DISCLAIMERS_FAILURE: 'get_disclaimers/failure',

  RESET_QR_IMAGE: 'qr_image/reset',

  SET_UTM_PARAMETERS: 'member/set_utm_parameters',
}
