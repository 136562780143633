export default () => ({
  meta: {
    loadedOn: null,
    error: {
      init: false,
      redeem: false,
      setup: false,
      turnOff: false,
      incentiveCatalog: false,
    },
    isLoading: {
      init: false,
      redeem: false,
      setup: false,
      turnOff: false,
      incentiveCatalog: false,
    },
  },
  preferences: {},
  pointSummary: [],
  directIncentivePointSummary: [],
  incentivesHistory: [],
  rewardsAvailable: [],
  basket: [],
  memberCoverageBalances: [],
  directIncentiveBalances: [],
  dependentCoverageBalances: {},
  tokenSummary: [],
  rewardBoxesAvailable: [],
  selectedRewardBox: null,
  remainingMoney: 0,
  deliveryAddress: {},
  autoRewardDeliveryAddress: {},
  selectedAutoReward: {},
  activeAutoReward: null,
  confirmAutoReward: false,
  dependents: [],
  dependentForRedemption: undefined,
  incentiveCatalog: {},
  rewardsConfig: {},
})
