<template>
  <VerificationLayout>
    <template #default>
      <VerifyEmail
        v-if="currentStep === 'enrollment'"
        key="enrollment"
        :type="enrollmentType"
        :codeError="verifyCodeError"
        @next="handleVerification"
      />
      <VerifyEmail
        v-if="currentStep === 'primary'"
        key="primary"
        :type="primaryType"
        :codeError="verifyCodeError"
        @next="handleVerification"
      />
      <VerificationConfirmation v-if="currentStep === 'confirmation'" />
    </template>
  </VerificationLayout>
</template>
<script>
import emailVerificationMixin from '@platform-shared/mixins/emailVerificationMixin'
import VerificationConfirmation from './VerificationConfirmation.vue'
import VerificationLayout from '~/components/layouts/VerificationLayout.vue'
import VerifyEmail from './VerifyEmail.vue'

export default {
  components: {
    VerificationLayout,
    VerifyEmail,
    VerificationConfirmation,
  },
  mixins: [emailVerificationMixin],
  computed: {
    currentStep() {
      return this.emailsToVerify
    },
  },
  methods: {
    async handleVerification(totp, type) {
      if (this.currentStep === 'confirmation') {
        this.$router.push({ name: 'rewards' })
        return
      }

      await this.submitVerification(totp, type)
    },
  },
}
</script>
